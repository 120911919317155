














































import { API } from 'client-website-ts-library/services';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Loader from '../UI/Loader.vue';

@Component({
  components: {
    Loader,
  },
})
export default class HomeLoanCalculator extends Vue {
  @Prop()
  private readonly hideAmount!: boolean;

  @Prop({ default: 500000 })
  private readonly amount!: number;

  private principal = this.amount;

  private depositAmount = this.amount * 0.2;

  private loanTerm = 30;

  private interestRate = 0;

  private loading = true;

  private result = '';

  private needsLMI = false;

  mounted() {
    API.Calculators.GetHomeLoanConfig().then((config) => {
      this.interestRate = config.InterestRate;

      this.loading = false;

      this.calculate();
    });
  }

  calculate() {
    const rate = (this.interestRate / 12) / 100;
    const repayments = this.loanTerm * 12;

    const principal = this.principal - this.depositAmount;

    this.needsLMI = this.depositAmount / this.principal < 0.2;

    const monthly = Math.max(0, principal / (((1 + rate) ** repayments - 1) / (rate * (1 + rate) ** repayments)));

    this.result = `$${Math.ceil(monthly).toLocaleString()}`;
  }
}
